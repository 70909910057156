import { Grid } from "@mui/material";
import { React, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router";
import { useSearchParams } from "react-router-dom";
import ResetPassword from "../../components/Buttons/ResetPassword";
import tryAndCatchRequestWithErrorHandling from "../../components/errorCode/errorCode";
import TextFieldConfirmPassword from "../../components/TextFields/ConfirmPassword";
import TextFieldPassword from "../../components/TextFields/Password";
import {
    passwordRecoved,
    registerChange,
} from "../../redux/actions/auth.action";
import API from "../../redux/API";

export default function Reset() {
    const dispatch = useDispatch();
    const history = useNavigate();

    const [params] = useSearchParams();
    const { register, registerErrors } = useSelector((state) => state.auth);

    const changeInput = (item, value) => {
        dispatch(registerChange(item, value));
        dispatch(passwordRecoved(register, registerErrors, item));
    };

    useEffect(() => {
        async function catchFunction() {
            history("/");
        }

        async function errorFunction() {
            history("/");
        }

        async function requestFunction() {
            return API.get(
                `user/verifyResetToken?token=${params.get("token")}`,
            );
        }
        const verifyReferral = async () => {
            await tryAndCatchRequestWithErrorHandling(
                dispatch,
                null,
                requestFunction,
                1100,
                null,
                errorFunction,
                catchFunction,
            );
        };

        verifyReferral();
    }, [params, dispatch, history]);

    return (
        <Grid
            container
            direction="row"
            justifyContent="space-between"
            alignItems="flex-start"
            style={{
                backgroundColor: "#FFFFFF",
                margin: "10px",
                textAlign: "center",
            }}
        >
            {TextFieldPassword(changeInput)}
            {TextFieldConfirmPassword(changeInput)}
            {ResetPassword()}
        </Grid>
    );
}
