import { React } from "react";
import { Grid, TextField } from "@mui/material";
import { useSelector } from "react-redux";

function TextFieldLoginUsername(processText) {
    const { loginErrors } = useSelector((state) => state.auth);

    return (
        <Grid item xs={12} style={{ margin: "10px" }}>
            <TextField
                error={
                    loginErrors && loginErrors.username !== null
                        ? loginErrors.username
                        : null
                }
                helperText={
                    loginErrors && loginErrors.username !== null
                        ? loginErrors.username
                        : null
                }
                id="outlined-email-input"
                label="Email or Username"
                type="text"
                autoComplete="username"
                onChange={(e) => processText("loginname", e.target.value)}
            />
        </Grid>
    );
}

export default TextFieldLoginUsername;
