import { Visibility, VisibilityOff } from "@mui/icons-material";
import {
    FormControl,
    FormHelperText,
    Grid,
    IconButton,
    InputAdornment,
    InputLabel,
    OutlinedInput,
} from "@mui/material";
import { React, useState } from "react";
import { useSelector } from "react-redux";

function TextFieldCurrentPassword(processText) {
    const { registerErrors } = useSelector((state) => state.auth);
    const [showPass, setShowPass] = useState(false);
    const setShow = () => setShowPass((value) => !value);
    return (
        <Grid item xs={12} style={{ margin: "10px" }}>
            <FormControl variant="outlined">
                <InputLabel htmlFor="outlined-oldPassword">
                    Current Password
                </InputLabel>
                <OutlinedInput
                    error={
                        registerErrors && registerErrors.oldpassword !== null
                            ? registerErrors.oldpassword
                            : null
                    }
                    id="outlined-oldPassword"
                    label="Current Password"
                    type={!showPass && "password"}
                    onChange={(e) => processText("oldPassword", e.target.value)}
                    endAdornment={
                        <InputAdornment position="end">
                            <IconButton
                                aria-label="toggle oldPassword visibility"
                                onClick={setShow}
                                onMouseDown={setShow}
                                edge="end"
                            >
                                {showPass ? <Visibility /> : <VisibilityOff />}
                            </IconButton>
                        </InputAdornment>
                    }
                />
                {registerErrors && registerErrors.oldPassword !== null ? (
                    <FormHelperText error id="outlined-oldPassword-error">
                        {registerErrors.oldPassword}
                    </FormHelperText>
                ) : null}
            </FormControl>
        </Grid>
    );
}

export default TextFieldCurrentPassword;
