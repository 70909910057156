import { Grid, TextField } from "@mui/material";
import { useEffect, useState, React } from "react";
import { useSelector } from "react-redux";

function TextFieldSurname(processText, signupDisabled) {
    const { registerErrors } = useSelector((state) => state.auth);
    const { currentUser } = useSelector((state) => state.auth);
    const perpetualUser = useSelector((state) => state.dashboard.boards);
    const [textValue, setTextValue] = useState("");
    function updateValue(value) {
        setTextValue(value);
        processText("surname", value);
    }
    useEffect(() => {
        if (currentUser !== null && currentUser !== undefined) {
            setTextValue(currentUser.surname);
        }
    }, [currentUser, perpetualUser]);

    return (
        <Grid item xs={12} style={{ margin: "10px" }}>
            <TextField
                error={
                    registerErrors && registerErrors.surname !== null
                        ? registerErrors.surname
                        : null
                }
                helperText={
                    registerErrors && registerErrors.surname !== null
                        ? registerErrors.surname
                        : null
                }
                id="outlined-last-name-input"
                label="Last Name"
                type="text"
                autoComplete="last-name"
                onChange={(e) => updateValue(e.target.value)}
                value={textValue}
                disabled={signupDisabled}
            />
        </Grid>
    );
}

export default TextFieldSurname;
