import { Grid, TextField } from "@mui/material";
import { useSelector } from "react-redux";
import React from "react";

function TextFieldConfirmEmail(onChangeHandler) {
    const { register, registerErrors } = useSelector((state) => state.auth);

    const handleChange = (e) => {
        e.preventDefault();
    };

    return (
        <Grid item xs={12} style={{ margin: "10px" }}>
            {register.email && (
                <TextField
                    error={
                        registerErrors && registerErrors.confirmEmail !== null
                            ? registerErrors.confirmEmail
                            : null
                    }
                    helperText={
                        registerErrors && registerErrors.confirmEmail !== null
                            ? registerErrors.confirmEmail
                            : null
                    }
                    id="outlined-confirm-email-input"
                    label="Confirm Email"
                    type="required"
                    onCut={handleChange}
                    onCopy={handleChange}
                    onPaste={handleChange}
                    onChange={(e) =>
                        onChangeHandler("confirmEmail", e.target.value)
                    }
                />
            )}
        </Grid>
    );
}

export default TextFieldConfirmEmail;
