import { React } from "react";
import { Button, Grid, Typography } from "@mui/material";
import SimpleMediaQuery from "../../pages/Dashboard/SimpleMediaQuery";
import Board from "../Board/Board";

const openInNewTab = (url) => {
    const newWindow = window.open(url, "_blank", "noopener,noreferrer");
    if (newWindow) newWindow.opener = null;
};

const onClickUrl = (url) => () => openInNewTab(url);

function Boards() {
    const nHorizontal = SimpleMediaQuery();
    if (nHorizontal) {
        return (
            <Grid container alignSelf="center">
                <Grid item xs={3} alignSelf="center">
                    <Board value={25} />
                    <Board value={100} />
                </Grid>
                <Grid item xs={3} alignSelf="center">
                    <Board value={50} />
                </Grid>
                <Grid item xs={3} alignSelf="center">
                    <Board value={400} />
                    <Board value={1600} />
                </Grid>
                <Grid item xs={3} alignSelf="center">
                    <Board value={5000} />
                </Grid>
                <Grid item xs={12}>
                    <Grid
                        container
                        direction="row"
                        justifyContent="center"
                        alignItems="center"
                        textAlign="center"
                    >
                        <Grid item xs={12}>
                            <Button
                                variant="contained"
                                style={{
                                    margin: "5px",
                                    backgroundColor: "white",
                                    color: "#01314D",
                                }}
                                onClick={onClickUrl(
                                    "https://www.ggmovement.org/donate/",
                                )}
                            >
                                <Typography>GGM Donate</Typography>
                            </Button>
                        </Grid>
                        <Grid item xs={12}>
                            <Button
                                variant="contained"
                                style={{
                                    margin: "5px",
                                    backgroundColor: "white",
                                    color: "#01314D",
                                }}
                                onClick={onClickUrl(
                                    "https://www.ggmovement.org/loveheart/",
                                )}
                            >
                                <Typography>LoveHeart Request</Typography>
                            </Button>
                        </Grid>
                        <Grid
                            item
                            xs={12}
                            backgroundColor="white"
                            color="#01314D"
                            textAlign="center"
                        >
                            <Typography variant="body1">
                                Please email support@giftoflegacyglobal.com for
                                any help or concerns
                            </Typography>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        );
    }
    return (
        <Grid container>
            <Grid item xs={2} />
            <Grid item xs={8}>
                <Board value={25} />
            </Grid>
            <Grid item xs={2} />
            <Grid item xs={2} />
            <Grid item xs={8}>
                <Board value={50} />
            </Grid>
            <Grid item xs={2} />
            <Grid item xs={2} />
            <Grid item xs={8}>
                <Board value={100} />
            </Grid>
            <Grid item xs={2} />
            <Grid item xs={2} />
            <Grid item xs={8}>
                <Board value={400} />
            </Grid>
            <Grid item xs={2} />
            <Grid item xs={2} />
            <Grid item xs={8}>
                <Board value={1600} />
            </Grid>
            <Grid item xs={2} />
            <Grid item xs={2} />
            <Grid item xs={8}>
                <Board value={5000} />
            </Grid>
            <Grid item xs={2} />
            <Grid item xs={12}>
                <Grid
                    container
                    direction="row"
                    justifyContent="center"
                    alignItems="center"
                    textAlign="center"
                >
                    <Grid item xs={12}>
                        <Button
                            variant="contained"
                            style={{
                                margin: "5px",
                                backgroundColor: "white",
                                color: "#01314D",
                            }}
                            onClick={onClickUrl(
                                "https://www.ggmovement.org/donate/",
                            )}
                        >
                            <Typography>GGM Donate</Typography>
                        </Button>
                    </Grid>
                    <Grid item xs={12}>
                        <Button
                            variant="contained"
                            style={{
                                margin: "5px",
                                backgroundColor: "white",
                                color: "#01314D",
                            }}
                            onClick={onClickUrl(
                                "https://www.ggmovement.org/beneficiary/",
                            )}
                        >
                            <Typography>LoveHeart Request</Typography>
                        </Button>
                    </Grid>
                    <Grid
                        item
                        xs={12}
                        backgroundColor="white"
                        color="#01314D"
                        textAlign="center"
                    >
                        <Typography variant="body1">
                            Please email support@giftoflegacyglobal.com for any
                            help or concerns
                        </Typography>
                    </Grid>
                </Grid>
            </Grid>
        </Grid>
    );
}

export default Boards;
