import { React } from "react";
import { Grid, Typography } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { loginChange, loginValidation } from "../../redux/actions/auth.action";
import Login from "../Buttons/Login";
import ForgotPasswordLink from "../TextFields/ForgotPasswordLink";
import TextFieldLoginPassword from "../TextFields/LoginPassword";
import TextFieldLoginUsername from "../TextFields/LoginUsername";
import RememberMeSlider from "../TextFields/RememberMeSlider";

function LoginComponent() {
    const dispatch = useDispatch();
    const { login, loginErrors } = useSelector((state) => state.auth);

    const changeInput = (item, value) => {
        dispatch(loginChange(item, value));
        dispatch(loginValidation(login, loginErrors, item));
    };

    return (
        <Grid
            container
            direction="row"
            justifyContent="center"
            alignItems="flex-start"
        >
            <Grid item xs={12} style={{ margin: "10px" }}>
                <Typography variant="h3">Welcome back!</Typography>
            </Grid>
            {TextFieldLoginUsername(changeInput)}
            {TextFieldLoginPassword(changeInput)}
            {RememberMeSlider(changeInput)}
            {Login()}
            {ForgotPasswordLink()}
            <Grid
                container
                direction="row"
                justifyContent="center"
                style={{
                    backgroundColor: "#FFFFFF",
                    textAlign: "center",
                    marginTop: "50px",
                }}
            >
                <Typography variant="h5">Support</Typography>
                <Grid
                    item
                    xs={12}
                    backgroundColor="white"
                    color="#01314D"
                    textAlign="center"
                >
                    <Typography variant="body1">
                        Please email support@giftoflegacyglobal.com for any help
                        or concerns
                    </Typography>
                </Grid>
            </Grid>
        </Grid>
    );
}

export default LoginComponent;
