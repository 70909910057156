import { React } from "react";
import { useRoutes } from "react-router-dom";
import DrawerHeader from "./components/DrawerHeader/DrawerHeader";
import Forgot from "./components/Forgot/Forgot";
import Auth from "./pages/Auth/Auth";
import Dashboard from "./pages/Dashboard/Dashboard";
import Info from "./pages/Info/Info";
import Login from "./pages/Login/Login";
import Register from "./pages/Register/Register";
import Reset from "./pages/Reset/Reset";
import Verification from "./pages/Verification/Verification";

function App() {
    const element = useRoutes([
        {
            path: "",
            element: <Auth />,
            children: [
                { path: "", element: <Login /> },
                { path: "forgot", element: <Forgot /> },
                { path: "register", element: <Register /> },
                { path: "reset", element: <Reset /> },
                { path: "verification", element: <Verification /> },
            ],
        },

        {
            path: "dashboard",
            element: <DrawerHeader />,
            children: [
                { path: "", element: <Dashboard /> },
                { path: "personal-info", element: <Info /> },
            ],
        },
    ]);

    return element;
}

export default App;
