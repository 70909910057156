import { Visibility, VisibilityOff } from "@mui/icons-material";
import {
    FormControl,
    FormHelperText,
    Grid,
    IconButton,
    InputAdornment,
    InputLabel,
    OutlinedInput,
} from "@mui/material";
import { useState, React } from "react";
import { useSelector } from "react-redux";

function TextFieldConfirmPassword(processText) {
    const handleChange = (e) => {
        e.preventDefault();
    };

    const { registerErrors } = useSelector((state) => state.auth);
    const [showPass, setShowPass] = useState(false);
    const setShow = () => setShowPass((value) => !value);
    return (
        <Grid item xs={12} style={{ margin: "10px" }}>
            <FormControl variant="outlined">
                <InputLabel htmlFor="outlined-confirm-password">
                    Confirm Password
                </InputLabel>
                <OutlinedInput
                    error={
                        registerErrors && registerErrors.confirmPass !== null
                            ? registerErrors.confirmPass
                            : null
                    }
                    id="outlined-confirm-password"
                    onCut={handleChange}
                    onCopy={handleChange}
                    onPaste={handleChange}
                    label="Confirm Password"
                    type={!showPass && "password"}
                    onChange={(e) => processText("confirmPass", e.target.value)}
                    endAdornment={
                        <InputAdornment position="end">
                            <IconButton
                                aria-label="toggle password visibility"
                                onClick={setShow}
                                edge="end"
                            >
                                {showPass ? <Visibility /> : <VisibilityOff />}
                            </IconButton>
                        </InputAdornment>
                    }
                />
                {registerErrors && registerErrors.confirmPass !== null ? (
                    <FormHelperText error id="outlined-confirm-password-error">
                        {registerErrors.confirmPass}
                    </FormHelperText>
                ) : null}
            </FormControl>
        </Grid>
    );
}

export default TextFieldConfirmPassword;
