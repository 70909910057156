import { React } from "react";
import { Grid, Typography } from "@mui/material";
import EmailOptInBoardActivity from "./EmailOptInBoardActivity";
import EmailOptInCustomerSupport from "./EmailOptInCustomerSupport";
import EmailOptInMarketing from "./EmailOptInMarketing";

function EmailOptIn(handleChange) {
    return (
        <Grid
            container
            direction="row"
            justifyContent="center"
            alignItems="center"
            style={{ textAlign: "flex-end", margin: "10px" }}
        >
            <Grid item xs={12}>
                <Typography>
                    I agree to receive emails about the following:
                </Typography>
            </Grid>
            {EmailOptInCustomerSupport(handleChange)}
            {EmailOptInBoardActivity(handleChange)}
            {EmailOptInMarketing(handleChange)}
        </Grid>
    );
}

export default EmailOptIn;
