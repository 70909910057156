import { React } from "react";
import { Grid, Typography } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { registerChange, testFn } from "../../redux/actions/auth.action";
import ResetPasswordEmail from "../Buttons/ResetPasswordEmail";
import TextFieldEmail from "../TextFields/Email";

function Forgot() {
    const dispatch = useDispatch();

    const { register, registerErrors } = useSelector((state) => state.auth);

    const processText = (item, value) => {
        dispatch(registerChange(item, value));
        dispatch(testFn(register, registerErrors, item));
    };

    return (
        <Grid container>
            <Grid item xs={12}>
                <h1>Forgot your password?</h1>
            </Grid>
            <Grid item xs={12}>
                <Typography>
                    Enter your email and we send you a password reset link.
                </Typography>
            </Grid>
            {TextFieldEmail(processText)}
            {ResetPasswordEmail()}
        </Grid>
    );
}

export default Forgot;
