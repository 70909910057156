import { Grid, TextField } from "@mui/material";
import { useEffect, useState, React } from "react";
import { useSelector } from "react-redux";

function TextFieldFirstName(processText, signupDisabled) {
    const { registerErrors } = useSelector((state) => state.auth);
    const [textValue, setTextValue] = useState("");
    const { currentUser } = useSelector((state) => state.auth);
    function updateValue(value) {
        setTextValue(value);
        processText("name", value);
    }

    useEffect(() => {
        if (currentUser !== null && currentUser !== undefined) {
            setTextValue(currentUser.name);
        }
    }, [currentUser]);

    return (
        <Grid item xs={12} style={{ margin: "10px" }}>
            <TextField
                error={
                    registerErrors && registerErrors.name !== null
                        ? registerErrors.name
                        : null
                }
                helperText={
                    registerErrors && registerErrors.name !== null
                        ? registerErrors.name
                        : null
                }
                id="outlined-first-name-input"
                label="First Name"
                type="text"
                autoComplete="first-name"
                onChange={(e) => updateValue(e.target.value)}
                value={textValue}
                disabled={signupDisabled}
            />
        </Grid>
    );
}

export default TextFieldFirstName;
