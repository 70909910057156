import { Visibility, VisibilityOff } from "@mui/icons-material";
import {
    FormControl,
    FormHelperText,
    Grid,
    IconButton,
    InputAdornment,
    InputLabel,
    OutlinedInput,
} from "@mui/material";
import { React, useState } from "react";
import { useSelector } from "react-redux";

function TextFieldLoginPassword(processText) {
    const { loginErrors } = useSelector((state) => state.auth);
    const [showPass, setShowPass] = useState(false);
    const setShow = () => setShowPass((value) => !value);
    return (
        <Grid item xs={12} style={{ margin: "10px" }}>
            <FormControl variant="outlined">
                <InputLabel htmlFor="outlined-password">Password</InputLabel>
                <OutlinedInput
                    error={
                        loginErrors && loginErrors.password !== null
                            ? loginErrors.password
                            : null
                    }
                    id="outlined-password"
                    label="Password"
                    type={!showPass && "password"}
                    onChange={(e) => processText("password", e.target.value)}
                    endAdornment={
                        <InputAdornment position="end">
                            <IconButton
                                aria-label="toggle password visibility"
                                onClick={setShow}
                                onMouseDown={setShow}
                                edge="end"
                            >
                                {showPass ? <Visibility /> : <VisibilityOff />}
                            </IconButton>
                        </InputAdornment>
                    }
                />
                {loginErrors && loginErrors.password !== null ? (
                    <FormHelperText error id="outlined-password-error">
                        {loginErrors.password}
                    </FormHelperText>
                ) : null}
            </FormControl>
        </Grid>
    );
}

export default TextFieldLoginPassword;
